import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InlineIcon as IconifyIcon } from "@iconify/react";
import { faJs } from "@fortawesome/free-brands-svg-icons/faJs";
import { faReact } from "@fortawesome/free-brands-svg-icons/faReact";
import { faCss3 } from "@fortawesome/free-brands-svg-icons/faCss3";
import { faNodeJs } from "@fortawesome/free-brands-svg-icons/faNodeJs";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons/faGitAlt";
import isiRedux from "@iconify/icons-simple-icons/redux";
import isiGraphQl from "@iconify/icons-simple-icons/graphql";
import isiApolloGraphQl from "@iconify/icons-simple-icons/apollographql";

export default {
  firstname: "Khadia",
  them: "her",
  name: "Khadia Mowle",
  path: "/team/khadia",
  email: "khadia@codia.co.uk",
  title: "Software Consultant",
  subtitle: "Director",
  icons: [
    (props) => <FontAwesomeIcon icon={faJs} {...props} />,
    (props) => <FontAwesomeIcon icon={faReact} {...props} />,
    (props) => <IconifyIcon icon={isiRedux} {...props} />,
    (props) => <FontAwesomeIcon icon={faCss3} {...props} />,
    (props) => <FontAwesomeIcon icon={faNodeJs} {...props} />,
    (props) => <IconifyIcon icon={isiGraphQl} {...props} />,
    (props) => <IconifyIcon icon={isiApolloGraphQl} {...props} />,
    (props) => <FontAwesomeIcon icon={faGitAlt} {...props} />,
  ],
  available: "Please enquire",
  linkedin: "linkedin.com/in/khadiaevans",
  cv: "/cv/codia_software_cv_khadia_mowle.pdf",
  about:
    "I am currently working with Hive Learning as a software consultant specialising in React and React Native app development. Over the last few years I have worked with React, Redux, SCSS and NodeJS to build web applications and more recently React Native and GraphQL to power our mobile and web apps. I'm passionate about creating great user experiences for our clients and clean codebases for fellow developers.",
  experience: [
    {
      role: "Software Consultant",
      type: "Contract",
      thumbnail: "/images/clients/hive.png",
      company: "Hive Learning",
      duration: "Sep 2018 - Present",
      tech: [
        "React Native",
        "CSS",
        "NodeJS",
        "GraphQL",
        "Apollo",
        "AWS",
        "Jest",
        "GitHub",
      ],
      projects: ["Learning Platform"],
      responsibilities: [],
    },
    {
      role: "Software Engineer / UX",
      type: "Contract",
      thumbnail: "/images/clients/storydoc.png",
      company: "Storydoc",
      duration: "Jun 2020 - Present",
      tech: [
        "React Native",
        "TypeScript",
        "CSS",
        "NodeJS",
        "GraphQL",
        "Apollo",
        "Jest",
        "Cypress",
        "Firebase",
        "GitHub",
      ],
      projects: ["POC Native App", "POC Web App"],
      responsibilities: [],
    },
    {
      role: "Frontend Software Engineer",
      type: "Employee",
      thumbnail: "/images/clients/ctm.png",
      company: "CompareTheMarket.com",
      duration: "Jan 2017 - Aug 2018 (1 year 8 months)",
      projects: ["MyCTM (Account Managment)", "Simples"],
      tech: [
        "React",
        "Redux",
        "CSS",
        "SCSS",
        "NodeJS",
        "AWS",
        "Jest",
        "GitHub",
      ],
      responsibilities: [],
    },
    {
      role: "Junior Web Developer",
      type: "Employee",
      thumbnail: "/images/clients/zazzle.png",
      company: "Zazzle Media",
      duration: "Sep 2015 - Dec 2016 (1 year 4 months)",
      projects: [],
      tech: ["HTML", "CSS", "jQuery", "GitHub"],
      responsibilities: [],
    },
  ],
};
